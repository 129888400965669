<!--
 * @Description: 选择班主任
 * @Author: xiawenlong
 * @Date: 2021-04-22 10:06:14
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-11 17:05:03
-->
<template>
  <el-dialog
    top="5vh"
    :close-on-click-modal="false"
    class="add-student"
    :visible.sync="dialogVisible"
  >
    <div class="title">角色列表</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="body">
      <div class="content">
        <p>已选总人数：{{ selectStudent.length }}人</p>
        <table-list
          ref="studentsByOrgTable"
          :loading="loading"
          :data="roleList"
          :columns="columns(this)"
          :pager="pager"
          :options="{
            selection: true,
            reserveSelection: true,
            rowKey: 'roleId',
            selectable: selectEvent,
          }"
          @search="onSearch"
          @selectionChange="handleSelectionChange"
        ></table-list>
      </div>
      <div class="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button :loading="submitLoading" type="primary" @click="submit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// const searchForm = () => [
//   {
//     label: '姓名进行搜索',
//     prop: 'keyword',
//     width: 150,
//   },
// ]
// 表格列配置
const columns = () => [
  {
    prop: 'roleName',
    label: '角色名称',
  },
]
import TableList from '@/components/TableList'
import { getAllRole, getUserAdmin } from '@/api/college'
import to from 'await-to'
export default {
  components: { TableList },
  data() {
    return {
      // searchForm,
      columns,
      dialogVisible: false,
      loading: false,
      studentsByOrg: [],
      selectStudent: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      submitLoading: false,
      queryParams: {},
      roleList: [],
      clientCode: '0a983d86-2527-5512-01a9-783cd0e1',
      userId: '',
    }
  },
  methods: {
    show(userId, roleIdList) {
      this.userId = userId
      this.dialogVisible = true
      this.pager.current = 1
      this.getAllRoleData(roleIdList)
    },
    async getAllRoleData(roleIdList) {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(getAllRole({ clientCode: this.clientCode }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.roleList = res.data.list
      this.$nextTick(() => {
        this.$refs.studentsByOrgTable.$refs.tableList.clearSelection()
        if (roleIdList) {
          this.roleList.map(item => {
            roleIdList.map(v => {
              if (item.roleId == v) {
                this.$refs.studentsByOrgTable.$refs.tableList.toggleRowSelection(item)
              }
            })
          })
        }
      })
    },
    async submit() {
      if (this.submitLoading) return
      this.submitLoading = true
      const [, err] = await to(
        getUserAdmin({
          roleIdList: this.selectStudent.map(item => item.roleId),
          userId: this.userId,
          clientCode: this.clientCode,
        }),
      )
      this.submitLoading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.dialogVisible = false
      this.$emit('success')
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getAllRoleData()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectStudent = val
    },
    selectEvent(row) {
      return !row.flag
    },
  },
}
</script>
<style lang="scss" scoped>
.add-student {
  ::v-deep.el-dialog {
    position: relative;
    width: 960px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 6px;
      background: #ff7b33;
    }
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 14px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .body {
      box-sizing: border-box;
      padding: 0 20px 20px 20px;
    }
    .title {
      height: 62px;
      line-height: 62px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .content {
      .table-list {
        .el-checkbox__input.is-disabled .el-checkbox__inner {
          display: none;
        }
      }
      p {
        position: absolute;
        top: 90px;
        right: 40px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
        line-height: 40px;
      }
    }
    .footer {
      padding-top: 20px;
      text-align: right;
    }
    .el-pagination {
      display: none;
    }
  }
}
</style>
