/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-26 09:51:22
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-24 15:29:53
 */
export default {
  courseCreate: '/course/supply/course/create',
  courseList: '/course/supply/course/page',
  courseInfo: '/course/supply/course/detail',
  courseUpdate: '/course/supply/course/update',
  courseStatus: '/course/supply/course/status',
  courseDel: '/course/supply/resourses/remove', //课程资源删除
  courseDelete: '/course/supply/course/delete', //学院课程库
  courseSections: '/course/supply/courses/sections', // 课程章节
  courseVideo: '/course/supply/course/section/video', // 课程可添加章节
  courseSectionSubmit: '/course/supply/course/sections/submit', // 提交课程章节
  courseNav: '/course/product-category/nav/tree',
  courseShopList: '/course/shop/courses/list',
  courseVideoAdd: '/course/supply/video/add', // 添加视频资源
  courseVideoUpdate: '/course/supply/video/update', // 修改视频资源
  courseResourses: '/course/supply/resourses/page', // 视频资源列表
  uploadMetaDataUrl: '/third/api/huaWeiUpload/uploadMetaDataUrl', // 华为基于URL视频上传
  videoInfo: '/course/supply/video/info', // 视频资源详情
  videoDetail: '/course/video/play/info', //获取视频资源
  consumerList: '/course/supply/consumer/query/statistics/order/list', // 查询机构下订单统计列表
  consumerDetailList: '/course/supply/consumer/query/personal/orderdetail/list', // 查询客户订单详情列表
  consumerInfo: '/course/supply/consumer/query/personal/info', // 查询客户基本信息
  Courseoptional: '/course/supply/class/optional/course/list', //班级课程列表接口
  courseCopy: '/course/supply/courses/copy', //复制副本
  downloadChapter: '/course/supply/course/chapter/section/template/export', //章节下载模板
  importChapter: '/course/supply/course/chapter/section/template/import', //章节导入模板
  downloadCourse: '/course/supply/course/template/export', //课程下载模板
  importCourse: '/course/supply/course/template/import', //课程模板导入
  importQuestion: '/course/manage/question/store/import/excel', //课程模板导入
  liveSearch: '/course/supply/course/section/live', //章节管理直播查询
  educationalCreate: '/course/supply/educational/admin/create', // 添加教务
  educationalDelete: '/course/supply/educational/admin/delete', // 删除教务
  educationalDeleteBatch: '/course/supply/educational/admin/delete/batch', // 批量删除教务
  educationalEdit: '/course/supply/educational/admin/edit', // 编辑教务
  educationalExcel: '/course/supply/educational/admin/excel/export', // 教务导入模板下载
  educationalImport: '/course/supply/educational/admin/excel/import', // 教务批量导入
  educationalInfo: '/course/supply/educational/admin/query', // 查询教务信息
  educationalList: '/course/supply/educational/admin/query/list', // 教务列表
  educationalRemove: '/course/supply/educational/admin/delete/batch', // 移除教务
  examList: '/course/manage/question/store/page', // 题库查询
  examDelete: '/course/manage/question/store/delete', // 题库删除
  examDown: '/course/manage/question/store/download/template', // 题库下载
  questionList: '/course/manage/question/store/detail/page', // 试题查询
  questionDelete: '/course/manage/question/store/delete/batch/question', // 试题删除
  examCreate: '/course/manage/question/store/create', //  题库创建
  examEdit: '/course/manage/question/store/edit', //  题库编辑
}
