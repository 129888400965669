<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-04-21 16:12:14
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-03-18 16:36:24
-->
<template>
  <div>
    <table-list
      title="管理员"
      :loading="loading"
      :data="adminList"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @search="onSearch"
      @size-change="handleSizeChange"
      @selectionChange="handleSelectionChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <AdminAdd ref="adminAdd" @success="getAdminListData"></AdminAdd>
    <Distribution ref="distribution" @success="getAdminListData"></Distribution>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '添加管理员',
    method: _this.handleAdd,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'primary',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '教务名称',
  },
  {
    prop: 'nickName',
    label: '管理员昵称',
  },
  {
    prop: 'userName',
    label: '用户名',
  },
  {
    prop: 'phone',
    label: '手机号码',
  },
  {
    prop: 'rolesName',
    label: '管理员角色',
  },
  {
    prop: 'createTime',
    label: '添加时间',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '分配角色',
    method: _this.handleRole,
  },
]
import TableList from '@/components/TableList'
import Distribution from './Distribution'
import AdminAdd from './CollegeAdminAdd'
import { getAdminList, adminDelete } from '@/api/college'
import to from 'await-to'
export default {
  name: 'CollegeAdmin',
  components: {
    TableList,
    AdminAdd,
    Distribution,
  },
  data() {
    return {
      btns,
      columns,
      operates,
      loading: false,
      adminList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      select: [],
      queryParams: {},
      clientCode: '0a983d86-2527-5512-01a9-783cd0e1',
    }
  },
  created() {
    this.getAdminListData()
  },
  methods: {
    async getAdminListData() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(
        getAdminList({ ...this.pager, ...this.queryParams, clientCode: this.clientCode }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.adminList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getAdminListData()
    },
    handleAdd() {
      this.$refs.adminAdd.show()
    },
    // 编辑
    async handleRole(row) {
      this.$refs.distribution.show(row.userId, row.roleIdList)
    },
    // 删除
    async handleDelete() {
      if (!this.select.length) return this.$message.warning('请先选择要管理员')
      let arr = []
      this.select.map(v => {
        arr.push(v.userId)
      })
      this.$confirm('你确认要删除吗?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const [, err] = await to(adminDelete({ userIdList: arr }))
          if (err) return this.$message({ type: 'error', message: err.msg })
          this.$message({ type: 'success', message: '删除成功' })
          this.getAdminListData()
        })
        .catch(() => {})
    },
    handleSelectionChange(val) {
      this.select = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getAdminListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getAdminListData()
    },
  },
}
</script>
