<template>
  <!-- <el-dialog
    :close-on-click-modal="false"
    class="add-student"
    width="30%"
    :visible.sync="dialogVisible"
    title="添加管理员"
  >
    <el-form ref="formDetail" :model="formDetail" label-width="120px" :rules="rules">
      <el-form-item label="学员名称：" prop="name">
        <el-input
          v-model="formDetail.name"
          placeholder="请输入学员名称"
          class="input-width"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号码：" prop="phone">
        <el-input
          v-model="formDetail.phone"
          placeholder="请输入手机号码"
          oninput="value=value.replace(/[^\d]/g,'')"
          class="input-width"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog> -->
  <el-dialog
    top="5vh"
    :close-on-click-modal="false"
    class="add-student"
    :visible.sync="dialogVisible"
  >
    <div class="title">按总学列表选择学员</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="body">
      <div class="content">
        <p>已选总人数：{{ selectStudent.length }}人</p>
        <table-list
          ref="studentsByOrgTable"
          :loading="loading"
          :data="studentsByOrg"
          :search-form="searchForm(this)"
          :columns="columns(this)"
          :pager="pager"
          :options="{
            selection: true,
            reserveSelection: true,
            rowKey: 'studentId',
            selectable: selectEvent,
          }"
          @search="onSearch"
          @selectionChange="handleSelectionChange"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></table-list>
      </div>
      <div class="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button :loading="submitLoading" type="primary" @click="submit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
const searchForm = () => [
  {
    label: '姓名进行搜索',
    prop: 'keyword',
    width: 150,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '姓名',
  },
  {
    prop: 'mobile',
    label: '手机号',
  },
  {
    prop: 'cardNo',
    label: '证件号',
  },
]
import TableList from '@/components/TableList'
import { getAdminAddSubmitTable } from '@/api/college'
import { educationalList } from '@/api/course'
import { validateInputMobile } from '@/utils/validate'
import to from 'await-to'
export default {
  name: 'AdminAdd',
  components: { TableList },
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      loading: false,
      selectStudent: [],
      studentsByOrg: [],
      searchForm,
      columns,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      formDetail: {
        name: '',
        phone: '',
      },
      rules: {
        name: [{ required: true, message: '请输入学员名称', trigger: 'blur' }],
        phone: [{ required: true, validator: validateInputMobile, trigger: 'blur' }],
      },
    }
  },
  methods: {
    show() {
      this.dialogVisible = true
      this.pager.current = 1
      this.getStudents()
    },
    async getStudents() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(educationalList({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.studentsByOrg = res.data.list
      this.pager.total = res.data.total
    },
    async submit() {
      if (this.submitLoading) return
      this.submitLoading = true
      const [, err] = await to(
        getAdminAddSubmitTable({ studentIds: this.selectStudent.map(item => item.studentId) }),
      )
      this.submitLoading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.dialogVisible = false
      this.$emit('success')
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getStudents()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectStudent = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getStudents()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getStudents()
    },
    selectEvent(row) {
      return !row.orgAdminFlag
    },
  },
}
</script>
<style lang="scss" scoped>
.add-student {
  ::v-deep.el-dialog {
    position: relative;
    width: 960px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 6px;
      background: #ff7b33;
    }
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 14px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .body {
      box-sizing: border-box;
      padding: 0 20px 20px 20px;
    }
    .title {
      height: 62px;
      line-height: 62px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .content {
      .table-list {
        .el-checkbox__input.is-disabled .el-checkbox__inner {
          display: none;
        }
      }
      p {
        position: absolute;
        top: 90px;
        right: 40px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
        line-height: 40px;
      }
    }
    .footer {
      padding-top: 20px;
      text-align: right;
    }
  }
}
</style>
