import config from './config/course'
import axios from '@/utils/request'
export const courseCreate = params => axios.post(config.courseCreate, params) //
export const courseList = params => axios.post(config.courseList, params) //
export const courseInfo = params => axios.post(config.courseInfo, params) //
export const courseUpdate = params => axios.post(config.courseUpdate, params) //
export const courseStatus = params => axios.post(config.courseStatus, params) //
export const courseNav = params => axios.post(config.courseNav, params) //
export const courseShopList = params => axios.post(config.courseShopList, params) //
export const courseSections = params => axios.post(config.courseSections, params) //
export const courseVideo = params => axios.post(config.courseVideo, params) //
export const courseSectionSubmit = params => axios.post(config.courseSectionSubmit, params) //
export const courseVideoAdd = params => axios.post(config.courseVideoAdd, params) //
export const courseVideoUpdate = params => axios.post(config.courseVideoUpdate, params) //
export const courseResourses = params => axios.post(config.courseResourses, params) //
export const uploadMetaDataUrl = params => axios.post(config.uploadMetaDataUrl, params) //
export const videoInfo = params => axios.post(config.videoInfo, params) //
// export const videoDetail = (url, params) => axios.post(config.videoDetail + '/' + url, params) //
export const videoDetail = params => axios.post(config.videoDetail, params)
export const courseDel = params => axios.post(config.courseDel, params) //
export const courseDelete = params => axios.post(config.courseDelete, params)
export const getConsumerList = params => axios.post(config.consumerList, params) //
export const getConsumerDetailList = params => axios.post(config.consumerDetailList, params) //
export const getConsumerInfo = params => axios.post(config.consumerInfo, params) //
export const Courseoptional = params => axios.post(config.Courseoptional, params) //
export const courseCopy = params => axios.post(config.courseCopy, params)

export const educationalCreate = params => axios.post(config.educationalCreate, params) //
export const educationalDelete = params => axios.post(config.educationalDelete, params) //
export const educationalDeleteBatch = params => axios.post(config.educationalDeleteBatch, params) //
export const educationalEdit = params => axios.post(config.educationalEdit, params) //
export const educationalExcel = params =>
  axios.post(config.educationalExcel, params, {
    responseType: 'blob',
  }) //
export const educationalImport = params => axios.post(config.educationalImport, params) //
export const educationalInfo = params => axios.post(config.educationalInfo, params) //
export const educationalList = params => axios.post(config.educationalList, params) //
export const educationalRemove = params => axios.post(config.educationalRemove, params) //
export const downloadChapter = params =>
  axios.post(config.downloadChapter, params, { responseType: 'blob' })
export const importChapter = params => axios.post(config.importChapter, params)
export const importQuestion = params => axios.post(config.importQuestion, params)
export const downloadCourse = params =>
  axios.post(config.downloadCourse, params, { responseType: 'blob' })
export const importCourse = params => axios.post(config.importCourse, params)
export const liveSearch = params => axios.post(config.liveSearch, params)
export const examList = params => axios.post(config.examList, params)
export const examDelete = params => axios.post(config.examDelete, params)
export const examDown = params => axios.post(config.examDown, params, { responseType: 'blob' })
export const questionList = params => axios.post(config.questionList, params)
export const questionDelete = params => axios.post(config.questionDelete, params)
export const examCreate = params => axios.post(config.examCreate, params)
export const examEdit = params => axios.post(config.examEdit, params)
